"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data: function data() {
    return {
      checkedGoods: ['1', '2', '3'],
      goods: [{
        id: '1',
        title: '进口香蕉',
        desc: '约250g，2根',
        price: 200,
        num: 1,
        thumb: 'https://img.yzcdn.cn/public_files/2017/10/24/2f9a36046449dafb8608e99990b3c205.jpeg'
      }, {
        id: '2',
        title: '陕西蜜梨',
        desc: '约600g',
        price: 690,
        num: 1,
        thumb: 'https://img.yzcdn.cn/public_files/2017/10/24/f6aabd6ac5521195e01e8e89ee9fc63f.jpeg'
      }, {
        id: '3',
        title: '美国伽力果',
        desc: '约680g/3个',
        price: 2680,
        num: 1,
        thumb: 'https://img.yzcdn.cn/public_files/2017/10/24/320454216bbe9e25c7651e1fa51b31fd.jpeg'
      }]
    };
  },
  computed: {
    submitBarText: function submitBarText() {
      var count = this.checkedGoods.length;
      return '结算' + (count ? "(".concat(count, ")") : '');
    },
    totalPrice: function totalPrice() {
      var _this = this;

      return this.goods.reduce(function (total, item) {
        return total + (_this.checkedGoods.indexOf(item.id) !== -1 ? item.price : 0);
      }, 0);
    }
  },
  methods: {
    formatPrice: function formatPrice(price) {
      return (price / 100).toFixed(2);
    },
    onSubmit: function onSubmit() {
      this.$toast('点击结算');
    }
  }
};
exports.default = _default;